import { render, staticRenderFns } from "./childrenfun.vue?vue&type=template&id=733f5f3e&scoped=true&"
import script from "./childrenfun.vue?vue&type=script&lang=js&"
export * from "./childrenfun.vue?vue&type=script&lang=js&"
import style0 from "./childrenfun.vue?vue&type=style&index=0&id=733f5f3e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733f5f3e",
  null
  
)

export default component.exports