<template>
  <section class="childrenfun">
    <div class="content">
    </div>
    <section class="btn-container">
      <img class="button" src="~assets/images/childrenfun/btn.png" @click="inspectionAuthenticity">
    </section>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  created() {
    let headHTML = document.getElementsByTagName('head')[0].innerHTML;
    headHTML += '<link rel="icon" href="">';
    document.getElementsByTagName('head')[0].innerHTML = headHTML;
    //this.datas.jump_wxa.query = 'query=' + encodeURIComponent(this.$route.fullPath.split('?')[1] || '');
  },

  data() {
    return {
      datas: {
        loginSource: 'CYAN_GOURD',
        jump_wxa: {
          env_version: '',
          path: '/pagesB/audio/audio',
          query: 'albumNo=20241204000001'
        }
      },
      locationURL: ''
    }
  },

  methods: {
    inspectionAuthenticity() {
      if (this.locationURL) {
        location.href = this.locationURL;
        return;
      }
      axios({
        url: 'https://fd.hollo-edu.com/hullo-cloud/client/login/getURLScheme',
        method: 'post',
        data: JSON.stringify(this.datas),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        if (res.code) {
          this.$message.error(res.message);
          return;
        }
        this.locationURL = res.data.data;
        location.href = this.locationURL;
      });
    }
  }
}
</script>

<style lang="less" scoped>
.childrenfun {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: white;
  background-size: contain;
  background-position: center;
  display: block;
  background-repeat: repeat;
  overflow: scroll;

  .content {
    margin: 0 auto;
    background: url('~assets/images/childrenfun/bg.png') no-repeat;
    background-size: contain;
    width: 100vw;
    height: 136vw;
  }

  .btn-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 15vw;

    .button {
      width: 64.4vw;
      height: 12.8vw;

    }
  }
}
</style>
